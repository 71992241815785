import type { ParentProps } from "solid-js";
import { GLOBAL_ELEMENT_IDS } from "@core/ui/global-element-ids";
import styles from "./FullScreenLayout.module.css";
interface Props extends ParentProps {}

export const FullScreenLayout = (props: Props) => {
  return (
    <div id={GLOBAL_ELEMENT_IDS.main} data-component="container" class={styles["full-screen-layout"]}>
      <main data-component="content" id={GLOBAL_ELEMENT_IDS.mainContent} class={styles["full-screen-layout__content"]}>
        {props.children}
      </main>
    </div>
  );
};
