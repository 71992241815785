import { onMount } from "solid-js";
import { FullScreenLayout } from "@core/components/layouts/FullScreenLayout";
import { NotFoundScreen } from "@core/screens/NotFoundScreen";
import { useWire } from "@core/wire";

export default () => {
  const wire = useWire();
  onMount(() => {
    wire.services.threads.send(wire.services.threads.eventFactory.newResetEvent());
  });

  return (
    <FullScreenLayout>
      <NotFoundScreen modifier="full-screen" />
    </FullScreenLayout>
  );
};
